import { HTMLAttributes } from "react";

interface SpotifyProps extends HTMLAttributes<HTMLIFrameElement> {
    [key: string]: any;
    link: string;
    wide?: boolean;
    width?: number | string;
    height?: number | string;
    frameBorder?: number | string;
    allow?: string;
    className?: string;
  }
  
export default function SpotifyEmbed(props: SpotifyProps){
    const url = new URL(props.link);
    // https://open.spotify.com/track/1KFxcj3MZrpBGiGA8ZWriv?si=f024c3aa52294aa1
    return (
        <iframe
          className={"m-auto h-full max-h-[30rem] " + props.className}
          title="Spotify Web Player"
          src={`https://open.spotify.com/embed${url.pathname}`}
          width={props.width}
          height={props.height}
          frameBorder={props.frameBorder}
          allow={props.allow}
          style={{
            borderRadius: 8,
            ...props.style,
          }}
          />
    );
  };