import { FormEvent, FormEventHandler, useState } from "react";
import useApi from "../api";

interface LoginProps{
    onSubmit?: (token: string) => void;
    onOut?: () => void;
}

interface LoginForm{
    username: string;
    password: string;
}

export default function Login(props: LoginProps){
    const {login} = useApi();
    const [form, setForm] = useState<LoginForm>({username: "", password: ""})

    const submitLogin = async (event: FormEvent) => {
        event.preventDefault()
        const token = await login(form.username, form.password);
        props.onSubmit && props.onSubmit(token);
    }

    return (
        <div className="fixed w-screen h-screen flex bg-opacity-50 top-0 right-0 z-30 bg-black p-10" onClick={() => props.onOut && props.onOut()}>
            <div className="rounded bg-white min-w-[20rem] w-1/4 m-auto gap-3" onClick={(event) => {event.stopPropagation()}}>
                <div
                    style={{boxShadow: "0px 2px 5px black"}} 
                    className="p-2 bg-gray-100 rounded-t">
                    <h1>Login</h1>
                </div>
                <div className="">
                    <form onSubmit={async (event) => await submitLogin(event)} className="flex flex-col">
                        <div className="shadow-inner py-5 px-2 gap-2 w-full flex flex-col">
                            <input value={form.username} onInput={(input) => setForm({...form, username: input.currentTarget.value})} className="border-b px-2 rounded" type={"text"} name="user" placeholder="Username"/>
                            <input value={form.password} onInput={(input) => setForm({...form, password: input.currentTarget.value})} className="border-b px-2 rounded" type={"password"} name="pass" placeholder="Password"/>
                        </div>
                        <input type={"submit"} className="text-center shadow-2xl p-1 cursor-pointer bg-blue-800 w-full text-white rounded-b" value={"Log In"}/>
                    </form>
                </div>
            </div>
        </div>
    )
}