import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useCookies } from "react-cookie";
import config from "./config";

export default function useApi(){
    const [cookie, setCookie, removeCookie] = useCookies();
    const fetcher = axios.create({baseURL: config.api, headers: {
        Authorization: cookie.jwt
    }})
    fetcher.interceptors.response.use((response) => response, (error: AxiosError) => {
        if(error.response?.status == 401) console.log("unauthorized");
        throw error;
      });

    const login = async (username: string, password: string) => {
        const result = await fetcher.post<{message: string, token: string}>("auth/login", {username, password})
        setCookie("jwt", result.data.token);
        return result.data.token
    }
    const logOut = () => {
        removeCookie("jwt");
    }

    const admin = cookie.jwt && typeof cookie.jwt == "string"

    return {api: fetcher, login, logOut, admin}
}
