import config from "../config";
import DragPicture from "./edit/dragPicture";
import { Picture } from "./insta";
import useSWR from "swr"
import useApi from "../api";

export interface ImageProps{
    className?: string;
} 

export default function TitleImage(props: ImageProps){
    const {api} = useApi();
    const {data: pictures, error, isLoading, mutate} = useSWR("pictures", async () => (await api.get<Picture[]>(config.api + "/picture")).data)    
    if(!pictures || !pictures.length || error || isLoading) return <></>

    return (
        <div className={"aspect-video max-h-[450px] " + props.className}>
            <img style={{
            boxShadow: "2px 2px 10px black"
            }}  className="rounded shadow-2xl" src={config.images + pictures.sort((a, b) => a.order - b.order)[0].src}/>
        </div>
    );
}