import {useRef} from "react";
import DragPicture from "./dragPicture";
import useSWR from "swr";
import { Picture } from "../insta";
import config from "../../config";
import useApi from "../../api";

interface InstaProps{
    className?: string
}


export default function Insta(props: InstaProps){
    const {data: pictures, error, isLoading, mutate} = useSWR("pictures", async () => (await api.get<Picture[]>(config.api + "/picture")).data)
    const {api} = useApi();
    const imageInput = useRef<HTMLInputElement>(null);

    const addPicture = async () => {
        const form = new FormData()
        if(imageInput.current && imageInput.current.files && imageInput.current.files[0]){
            form.append("image", imageInput.current.files[0]);
            form.append("order", pictures ? pictures.length.toString() : "0");
            
            const result = await api.post(`${config.api}/picture`, form, { headers: {"Content-Type": "multipart/form-data"}})
            if(result.status === 200) mutate(pictures ? [...pictures, result.data] : [result.data]);
        };
    } 


    if(!pictures || !pictures.length || error || isLoading) return (
    <div className={`w-full h-full transition-all duration-500 shadow-2xl p-5 bg-blue-theme m-auto gap-4 columns-1 md:columns-2 lg:columns-3 ${props.className}`}>
        <div onClick={() => imageInput.current?.click()} className="py-32 border cursor-pointer rounded bg-white bg-opacity-5">
            <input name="image" ref={imageInput} accept="image/*" onInput={async () => await addPicture()} type={"file"} className="hidden" />
            <p className="text-center text-white font-thin text-2xl">Add Picture</p>
        </div>
    </div>)

    const reorder = (drag: number, drop: number) => {
        
        //find and remove the picture from its previous placement in the array
        const pic = pictures.splice(drag, 1)
        //add it into its new position
        pictures.splice(drop, 0, ...pic);
        mutate(async () => await Promise.all(pictures.map(async (picture, index) => {
            picture.order = index;
            await api.put(`${config.api}/picture/${picture.id}`, picture)
            return picture;
        })), 
        {
            optimisticData: pictures.map((picture, index) => {
                picture.order = index;
                return picture;
            })
        });
    }

    const deletePicture = async (id: number) => {
        const result = await api.delete(`${config.api}/picture/${id}`);
        if(result.status) mutate(pictures.filter(pic => pic.id !== id))
    }
    
    return (
        <div className={`w-full h-full transition-all duration-500 shadow-2xl p-5 bg-blue-theme m-auto gap-4 columns-1 md:columns-2 lg:columns-3 ${props.className}`}>
            {pictures.sort((a, b) => a.order - b.order).slice(1).map((pic, index) => (
                <div key={index} className="mb-4">
                    <DragPicture className="" onDelete={async (id) => await deletePicture(id)} onReorder={reorder} picture={pic}/>
                </div>
            ))}
            <div style={{ boxShadow: "2px 2px 10px black"}} onClick={() => imageInput.current?.click()} className="py-32 border border-black cursor-pointer rounded bg-white bg-opacity-5">
                <input name="image" ref={imageInput} accept="image/*" onInput={async () => await addPicture()} type={"file"} className="hidden" />
                <p className="text-center text-white font-thin text-2xl">Add Picture</p>
            </div>
        </div>
)}
