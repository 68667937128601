import dayjs, { Dayjs } from "dayjs";
import { useQuery } from "react-query";
import Countdown from "./countdown";
import useSWR from "swr";
import config from "../config";
import useApi from "../api";

interface Announcement{
    title: string,
    details?: string,
    date?: Dayjs,
}

export default function Announcement(){
    const {api} = useApi();
    const {data: announcement, error} = useSWR("announcement", async (key) => (await api.get<Announcement>(config.api + "/announcement/1")).data)
    if(error || !announcement || (announcement.date && dayjs(announcement.date).diff(dayjs()) < 0)) return <></>
    
    return (
        <div className="px-10">
            <h1 className="text-center text-6xl font-title uppercase p-2">{announcement.title}</h1>
            {announcement.details && <h2 className="text-center max-w-prose m-auto text-xl text-gray-600 font-title uppercase p-2">{announcement.details}</h2>}
            {announcement.date && <Countdown className="m-auto font-extralight text-gray-400 justify-center pt-10" date={dayjs.isDayjs(announcement.date) ? announcement.date : dayjs(announcement.date)}/>}
        </div>
    )
}