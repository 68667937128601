import useSWR from "swr"
import { Picture } from "../insta";
import config from "../../config";
import DragPicture from "./dragPicture";
import { useDrop } from "react-dnd";
import { ImageProps } from "../titleImage";
import useApi from "../../api";

export default function TitleImage(props: ImageProps){
    const {api} = useApi();
    const {data: pictures, error, isLoading, mutate} = useSWR("pictures", async () => (await api.get<Picture[]>(config.api + "/picture")).data);
    const [, dropRef] = useDrop({
        accept:"picture",
        drop: (item: { order:number }) => console.log(item) //props.onReorder(item.order, props.picture.order)
    });

    
    if(!pictures || !pictures.length) return (
        <div ref={dropRef} style={{ boxShadow: "2px 2px 10px black"}} onClick={() => {}} className={"py-32 flex h-full border w-full border-black cursor-pointer rounded bg-white bg-opacity-5 " + props.className}>
            <input name="image" accept="image/*" onInput={async () => {}} type={"file"} className="hidden" />
            <p className="text-center text-black font-thin w-full m-auto text-2xl">Add Picture</p>
        </div>
    )

    const reorder = (drag: number, drop: number) => {
        //find and remove the picture from its previous placement in the array
        const pic = pictures.splice(drag, 1)
        //add it into its new position
        pictures.splice(drop, 0, ...pic);
        mutate(async () => await Promise.all(pictures.map(async (picture, index) => {
            picture.order = index;
            await api.put(`${config.api}/picture/${picture.id}`, picture)
            return picture;
        })), 
        {
            optimisticData: pictures.map((picture, index) => {
                picture.order = index;
                return picture;
            })
        });
    }

    return (
        <DragPicture className="max-h-[450px] aspect-auto " onDelete={() => {}} onReorder={reorder} picture={pictures.sort((a, b) => a.order - b.order)[0]} />
    );
}