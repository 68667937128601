import dayjs, { Dayjs } from "dayjs";
import { Children, ReactElement, useState } from "react";
import useSWR from "swr";
import useApi from "../api";
import config from "../config";

export interface CalendarProps{
    className?: string;
    children?: ReactElement
}

export interface Event{
    id?: number,
    title: string,
    description: string,
    location?: string,
    date: Dayjs,
    link?: string,
    img?: string,
}

export default function Calendar(props: CalendarProps){
    const {api} = useApi();
    const {data: events, error} = useSWR("events", async () => (await api.get<Event[]>(config.api +"/event")).data.map(event => {event.date = dayjs(event.date); return event}));
    const [expanded, isExpanded] = useState(false)

    return (
        <div className={"h-screen font-extralight overflow-hidden  w-[30vw] md:w-[20vw]  lg:w-[15vw] transition-all duration-500 " + props.className}>
            <div
                onClick={() => {isExpanded(!expanded)}}
                style={{boxShadow: "1px 1px 1px darkgray"}}
                className="p-2 text-xl cursor-pointer md:cursor-auto bg-white text-center border-b">
                <h1>Events</h1>
            </div>
            <div style={{boxShadow: "inset 2px 3px 10px lightgray, inset -3px -2px 10px lightgray"}} 
            className={`overflow-hidden bg-white transition-all duration-1000 ${expanded ? "h-screen" : "h-0"} md:h-screen`}>
                <table
                    className="w-full">
                    <tbody className="w-full text-left">
                        {events && events.filter(event => event.date.isAfter(dayjs(), "D") || event.date.isSame(dayjs(), "D")).sort((a,b) => a.date.diff(b.date)).map((event, index) => (
                            <tr key={index} className="border-b last:border-none border-gray-200 w-full">
                                    <td className="py-3 overflow-clip px-2 flex">
                                    <a href={event.link}>
                                        <div className="justify-between gap-1 flex max-w-xs">
                                            { event.img && <img className="w-10 mr-2 aspect-square m-auto" src={event.img}/>}
                                            <div>
                                                <p className="text-sm font-bold uppercase tracking-wide">{event.title}</p>
                                                <p className="px-2 w-full whitespace-nowrap overflow-hidden text-xs text-ellipsis">{event.description}</p>
                                                <p className="px-2 text-xs">{event.date.format("ddd D-MM-YYYY")}</p>
                                            </div>
                                        </div>
                                    </a>
                                    </td>
                                </tr>
                        ))}
                        {props.children}
                    </tbody>
                </table>
            </div>
        </div>
    )
}