import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useQuery } from "react-query";
import CalendarView, {Event, CalendarProps} from "../calendar";
import useSWR from "swr";
import config from "../../config";
import useApi from "../../api";

export default function Calendar(props: CalendarProps){
    const {api} = useApi();
    const [event, setEvent] = useState<Event>({title: "", description: "", date: dayjs()});
    const [popUp, popped] = useState(false);
    const {data: events, mutate} = useSWR("events", async () => (await (await api.get<Event[]>(config.api + "/event")).data).map(event => {event.date = dayjs(event.date); return event}));


    const addEvent = async () => {
        events && mutate([
            ...events, 
            (await  (event.id 
                ? api.put<Event>(`${config.api}/event/${event.id}`, event)
                : api.post<Event>(config.api + "/event", event))
            ).data
        ],
        {
            optimisticData: [...events, event]
        })
        popped(false);
    };
    const deleteEvent = async (id: number) => {
        mutate(async () => {
            const result = await api.delete(`${config.api}/event/${id}`);
            if(result.status === 200) return events?.filter(event => event.id != id);
            return events;
            },
            {
                optimisticData: events ? events.filter(event => event.id != id) : []
            })
    };
    

    const [expanded, isExpanded] = useState(false)

    return (
        <>
            {popUp && (<div className="fixed w-screen h-screen flex bg-opacity-50 top-0 right-0 z-30 bg-black p-10" onClick={() => {popped(!popUp)}}>
                <div className="rounded bg-white m-auto" onClick={(event) => {event.stopPropagation()}}>
                    <div
                        style={{boxShadow: "0px 2px 2px black"}} 
                        className="p-2 bg-gray-100 rounded-t">
                        <h1>New Event</h1>
                    </div>
                    <div className="grid p-4 grid-cols-2 gap-2">
                        <input 
                            type="text"
                            onInput={(input) => setEvent({...event, title: (input.target as HTMLInputElement).value})}
                            value={event.title} 
                            placeholder="Title" 
                            className="px-2 w-full border"
                        />
                        <input 
                            type="date"
                            value={event.date.format("YYYY-MM-DD")}
                            onInput={(input) => setEvent({...event, date: dayjs((input.target as HTMLInputElement).valueAsDate)})} 
                            placeholder="Date" 
                            className="px-2 w-full border"
                        />
                        <input 
                            type="text"
                            value={event.description}
                            onInput={(input) => setEvent({...event, description: (input.target as HTMLInputElement).value})} 
                            placeholder="Description" 
                            className="px-2 w-full border"
                        />
                        <input 
                            type="text"
                            value={event.location}
                            onInput={(input) => setEvent({...event, location: (input.target as HTMLInputElement).value})} 
                            placeholder="Location" 
                            className="px-2 w-full border"
                        />
                        <input
                            type="text"
                            value={event.link}
                            onInput={(input) => setEvent({...event, link: (input.target as HTMLInputElement).value})} 
                            placeholder="Event Link" 
                            className="col-span-2 my-2 text-center px-2 w-full border"
                        />
                    </div>
                    <button onClick={async () => {await addEvent()}}  className="text-center bg-blue-800 w-full p-2 text-white rounded-b">{event.id ? "Update Event" : "Add Event"}</button>
                </div>
            </div>)}

            {/* Calendar view */}
            <div className={"h-screen font-extralight overflow-hidden  w-[30vw] md:w-[20vw]  lg:w-[15vw] transition-all duration-500 " + props.className}>
            <div
                onClick={() => {isExpanded(!expanded)}}
                style={{boxShadow: "1px 1px 1px darkgray"}}
                className="p-2 text-xl cursor-pointer md:cursor-auto bg-white text-center border-b">
                <h1>Events</h1>
            </div>
            <div style={{boxShadow: "inset 2px 3px 10px lightgray, inset -3px -2px 10px lightgray"}} 
            className={`overflow-hidden bg-white transition-all duration-1000 ${expanded ? "h-screen" : "h-0"} md:h-screen`}>
                <table
                    className="w-full">
                    <tbody className="w-full text-left">
                        {events && events.filter(event => event.date && (event.date.isAfter(dayjs(), "day") || event.date.isSame(dayjs(), "day"))).sort((a,b) => a.date.diff(b.date)).map((event, index) => (
                            <tr onClick={() => {
                                setEvent(event);
                                popped(true)}
                            } key={index} className="cursor-pointer border-b last:border-none border-gray-200 w-full">
                                    <td className="py-3 overflow-clip px-2">
                                        <div className="justify-between gap-1 flex max-w-xs">
                                            { event.img && <img className="w-10 mr-2 aspect-square m-auto" src={event.img}/>}
                                            <div className="w-full flex flex-col flex-grow">
                                                <p className="text-sm font-bold uppercase tracking-wide">{event.title}</p>
                                                <p className="px-2 w-full whitespace-nowrap overflow-hidden text-xs text-ellipsis">{event.description}</p>
                                                <p className="px-2 text-xs">{event.date.format("ddd D-MM-YYYY")}</p>
                                            </div>
                                            <button className="flex flex-shrink bg-red-700 text-white m-auto rounded-full px-2 hover:bg-red-900 font-sans font-bold" onClick={async (input) => {input.stopPropagation(); event.id && await deleteEvent(event.id)}}>
                                                <p>X</p>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                        ))}
                        <tr>
                            <td>
                                <button onClick={() => {popped(!popUp)}} className="border p-1 border-black text-white bg-blue-800 w-full">Add Event</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}