import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

interface CountdownProps{
    date: Dayjs,
    className?: string,
}

export default function Countdown(props: CountdownProps){
    // To calculate the time difference of two dates

    
    const dayDiff = props.date.diff(dayjs(), "days");
    const hourDiff = props.date.diff(dayjs(), "hours") % 24
    const minDiff = props.date.diff(dayjs(), "minutes") % 60
    const [secDiff, setSecDiff] = useState(props.date.diff(dayjs(), "seconds") % 60);

    useEffect(() => {
        setInterval(() => {
            setSecDiff(props.date.diff(dayjs(), "seconds") % 60)
        }, 1000)
    });
    
    return (
        <div className={"m-auto gap-5 flex flex-wrap text-center " + props.className}>
            <h3 className="text-4xl ">
                <p>{dayDiff}</p> 
                <p>Days</p>
            </h3>
            <h3 className="text-4xl ">
                <p>{hourDiff}</p> 
                <p>Hours</p>
            </h3>
            <h3 className="text-4xl ">
                <p>{minDiff}</p> 
                <p>Minutes</p>
            </h3>
            <h3 className="text-4xl ">
                <p>{secDiff + 1}</p> 
                <p>Seconds</p>
            </h3>
        </div>
    )
}