import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import useSWR from "swr";
import useApi from "../../api";
import config from "../../config";

interface Announcement{
    id?: number
    title: string,
    details?: string,
    date?: Dayjs,
}

export default function Announcement(){
    const [announcement, setAnnouncement] = useState({title: ""} as Announcement)
    const {data, error} = useSWR("announcement", async (key) => (await api.get<Announcement>(config.api + "/announcement/1")).data)
    const {api} = useApi();
    if(!(!data || error) && announcement.title == "") setAnnouncement(data);

    const createAnnouncement = async () => {
        let result;
        if(announcement.id) result = await api.put(config.api + "/announcement/1", announcement)
        else result = await api.post(config.api + "/announcement", announcement)
    }
    
    return (
        <div className="flex flex-col w-full p-5">
            <input
                name="title" 
                type={"text"}
                className="text-center m-auto w-[inherit] text-6xl font-title uppercase p-2" 
                value={announcement.title} 
                onInput={(input) => setAnnouncement({...announcement, title: (input.target as HTMLInputElement).value})} 
                placeholder="Announcement"/>
            <input 
                name="details"
                type={"text"}
                className="text-center w-full m-auto text-xl text-gray-600 font-title uppercase p-2" 
                value={announcement.details} 
                onInput={(input) => setAnnouncement({...announcement, details: (input.target as HTMLInputElement).value})} 
                placeholder="Details"/>
            <input 
                type="date" 
                className="m-auto font-extralight text-gray-400 justify-center"
                onInput={(input) => setAnnouncement({...announcement, date: dayjs((input.target as HTMLInputElement).valueAsDate)})}
                value={dayjs(announcement.date).format("YYYY-MM-DD")} 
                placeholder={"Countdown"}/>
            <button onClick={async () => await createAnnouncement()} className="border p-1 mt-2 border-black text-white bg-blue-800 rounded">Post Announcement</button>
        </div>
    )
}