import './App.css';
import VideoPop from './components/videoPop';
import SpotifyEmbed from './components/spotifyEmbed';
import Announcement from './components/announcement';
import Insta from './components/insta';
import Calendar from './components/calendar';
import AnnouncementEdit from './components/edit/announcement';
import InstaEdit from './components/edit/insta';
import CalendarEdit from './components/edit/calendar';
import { useContext, useState } from 'react';
import { AdminContext } from '.';
import TitleImage from './components/titleImage';
import TitleImageEdit from './components/edit/titleImage';
import Login from './components/login';
import {useCookies} from "react-cookie";
import useApi from './api';

function App() {
  const {admin, logOut} = useApi()
  let context = useContext(AdminContext);
  if(admin) context = true;

  const [popLog, login] = useState(false);
  return (
    <div className="font-scope relative">
      <div className='absolute z-30 opacity-10 hover:opacity-20 rounded-full cursor-pointer bottom-2 left-2 p-2 bg-blue-900' onClick={() => admin ? logOut() : login(!popLog)}/>
      <div>
          <VideoPop/>
      </div>
      {popLog && <Login onOut={() => login(false)}/>}
      <div className='flex'>
        <div className='w-screen md:w-[85vw]  relative min-h-screen flex-col'>
          <section className='m-auto h-full pt-5 gap-10 flex flex-col shadow-inner'>
          {context ? <AnnouncementEdit/> : <Announcement/>}
            <section className='px-10 lg:flex-row flex-col flex gap-5'>
              {context ? <TitleImageEdit /> : <TitleImage />}
              <SpotifyEmbed className='w-full flex flex-grow h-[21vh] lg:w-auto lg:min-h-[360px]' link='https://open.spotify.com/artist/56kAP8Glt3AVDWrOwxX9aB'/>
            </section>
            {context ? <InstaEdit className=""/> : <Insta className=""/>} 
          </section>
        </div>
        <div className='h-auto'>
          {context ? <CalendarEdit className='overflow-auto md:overflow-hidden md:sticky right-0 top-0 fixed' /> : <Calendar className='overflow-auto md:overflow-hidden md:sticky right-0 top-0 fixed' />}
        </div>
      </div>
    </div>
  );
}

export default App;
