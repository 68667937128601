import useSWR from "swr";
import useApi from "../api";
import config from "../config";

interface InstaProps{
    className?: string
}

export interface Picture{
    id?: number,
    fileType?: string,
    order: number,
    src: string
}

export default function Insta(props: InstaProps){
    const {api} = useApi();
    const {data: pictures, error, isLoading, mutate} = useSWR("pictures", async () => (await api.get<Picture[]>(config.api + "/picture")).data)

    return pictures && !error ? (
        <div className={`transition-all duration-500 shadow-2xl w-full p-5 bg-blue-theme m-auto gap-4 h-full columns-1 md:columns-2 lg:columns-3 ${props.className}`}>
            {pictures && pictures.sort((a, b) => a.order - b.order).slice(1).map((pic, index) => (
                <div key={index} className="mb-4 aspect-auto">
                    <img style={{
                    boxShadow: "2px 2px 10px black"
                  }}  className="rounded shadow-2xl" src={config.images + pic.src}/>
                </div>
            ))}
        </div>) : <></>
}