import { useDrag, useDrop } from "react-dnd"
import config from "../../config";
import { Picture } from "../insta"

interface dragProperties{
    className?: string,
    onReorder: (draggedItem: number, droppedItem: number) => void,
    onDelete: ((id: number) => void)
    picture: Picture
}

export default function DragPicture(props: dragProperties){
    const picture: Picture = props.picture;

    const [{isDragging}, dragRef] = useDrag({
        type: "picture",
        item: { order: picture.order},
        collect: (monitor) => ({isDragging: monitor.isDragging()}),
    })

    const [, dropRef] = useDrop({
        accept:"picture",
        drop: (item: { order:number }) => props.onReorder(item.order, picture.order)
    });


    return (
        <div ref={dropRef} className={"flex m-auto aspect-auto "}>
            <div onClick={() => props.onDelete && picture.id != null && props.onDelete(picture.id)} className="m-auto relative">
                <button  className="absolute top-0 right-0 flex font-sans bg-red-900 w-7 h-7 opacity-80 rounded-full hover:animate-pulse text-white">
                    <p className="m-auto ">X</p>    
                </button>
                <img 
                    ref={dragRef}
                    style={{ boxShadow: "2px 2px 10px black", opacity: isDragging ? "0.3" : "1"}} 
                    className={"rounded shadow-2xl m-auto " + props.className} src={config.images + props.picture.src}/>
            </div>
        </div>
    )
}