import { useEffect, useState } from "react"

function YoutubeEmbed(props: {embedId: string}){
  return (
    <div className="w-full relative h-full overflow-hidden">
      <iframe
        className="left-0 top-0 h-full w-full absolute"
        width="853" height="480"
        src={`https://www.youtube.com/embed/${props.embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
)}

export default function VideoPop(){
  const [expanded, isExpanded] = useState(false);

  useEffect(() => {
    setTimeout(() => isExpanded(!expanded), 2000)
  }, []);


  return (
    <div className="flex flex-col w-full h-full">
      <div className={`duration-1000 transition-all flex w-full ${expanded ? "h-[90vh]" : "h-0"}`}>
          <YoutubeEmbed embedId="GN75zTpRNFc?controls=0"/>
      </div>
      <div className="h-[3vh] cursor-pointer shadow-inner flex bg-blue-dark" onClick={() => isExpanded(!expanded)}>
        <div className={`duration-700 transition-all m-auto w-full hover:animate-pulse ${expanded ? "rotate-180" : ""}`}>
          <img src="/arrow.svg" className="m-auto h-4"/>
        </div>
      </div>
    </div>
  )
}